import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'
import IntrinsicValueView from './IntrinsicValueView';

function App () {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Finance Calculator</h1>
      </header>
      <Router>
        <Routes>
          <Route path="/" element={<IntrinsicValueView />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
