import {
  DiscountedCashFlowResponseData,
  FinancialsGrowthRequestBody,
  FinancialsGrowthResponseData,
  FormData,
} from './types'
import { API_URL } from './config'
import { defaultGrowthPeriods, defaultInputValues } from './defaults'
import { handleFinancialsGrowthResponse } from './requestFinancialsGrowth'
import {
  handleDiscountedCashFlowResponse,
} from './requestDiscountedCashFlow'

export const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent)
}

// Main function to submit form data
export const submitFormData = async (
  formData: FormData,
  setDiscountedCashFlowResponseData: React.Dispatch<React.SetStateAction<DiscountedCashFlowResponseData | null>>,
  setFinancialsGrowthResponseData: React.Dispatch<React.SetStateAction<FinancialsGrowthResponseData | null>>,
  setError: React.Dispatch<React.SetStateAction<{ message: string; id: number } | null>>,
) => {
  setError(null); // Reset error state before new request

  const financialsGrowthInput: FinancialsGrowthRequestBody = {
    symbols: formData.symbols,
    periods: defaultGrowthPeriods,
  };

  try {
    // Handle each request independently
    const discountedCashFlowPromise = handleDiscountedCashFlowResponse(formData, setDiscountedCashFlowResponseData, setError);
    const financialsGrowthPromise = handleFinancialsGrowthResponse(financialsGrowthInput, setFinancialsGrowthResponseData, setError);

    // Await both promises to ensure the try-catch block captures any errors
    await Promise.allSettled([discountedCashFlowPromise, financialsGrowthPromise]);
  } catch (error) {
    const errorMessage = `An error occurred: ${(error as Error).message}`;
    setError({ message: errorMessage, id: Date.now() });
    setTimeout(() => setError(null), 5000);
  }

  // Blur all input fields to hide the keyboard on mobile devices only
  if (isMobileDevice()) {
    const inputs = document.querySelectorAll('input');
    inputs.forEach((input) => input.blur());
  }
};

export const wakeupCall = async () => {
  try {
    const response = await fetch(API_URL + '/marco')
    if (response.ok) {
      console.log('Wakeup call responded successfully')
    } else {
      console.error(
        'Wakeup call failed with error code: ' + response.status)
    }
  } catch (error) {
    console.error('An error occurred:', error)
  }
}

export const getNewFormData = (params: URLSearchParams) => {
  const symbolsFromParams = params.get('symbols')

  const newFormData: FormData = {
    symbols: symbolsFromParams ? symbolsFromParams.split(',').map(symbol => symbol.trim()) : defaultInputValues.symbols,
    annual_cash_flow_discount: parseFloat(
      params.get('annual_cash_flow_discount') ||
      defaultInputValues.annual_cash_flow_discount as unknown as string),
    margin_of_safety: parseFloat(params.get('margin_of_safety') ||
      defaultInputValues.margin_of_safety as unknown as string),
    number_of_years: parseInt(params.get('number_of_years') ||
      defaultInputValues.number_of_years as unknown as string,
      defaultInputValues.number_of_years),
    maximal_average_growth_rate: parseFloat(
      params.get('maximal_average_growth_rate') ||
      defaultInputValues.maximal_average_growth_rate as unknown as string),
    maximal_market_cap_to_free_cash_flow_ratio: params.get(
      'maximal_market_cap_to_free_cash_flow_ratio') !== null
      ? parseFloat(
        params.get('maximal_market_cap_to_free_cash_flow_ratio') || '')
      : undefined,
    average_growth_rate_annual: params.get('average_growth_rate_annual') !==
    null
      ? parseFloat(params.get('average_growth_rate_annual') || '')
      : undefined,
    average_market_cap_to_free_cash_flow_ratio: params.get(
      'average_market_cap_to_free_cash_flow_ratio') !== null
      ? parseFloat(
        params.get('average_market_cap_to_free_cash_flow_ratio') || '')
      : undefined,
  }
  return newFormData
}

export const getUpdatedURL = (data: FormData) => {
  const params = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        // For arrays, join the array into a string with commas
        params.set(key, value.join(','));
      } else if (typeof value === 'string') {
        // For strings, check if it is not an empty string
        if (value !== '') {
          params.set(key, value);
        }
      } else {
        // For other types, convert to string directly
        params.set(key, String(value));
      }
    }
  });
  return params;
};

// function delay(ms: number) {
//     return new Promise( resolve => setTimeout(resolve, ms) );
// }
