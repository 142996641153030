import {FormData} from './types'

export const defaultInputValues: FormData = {
  symbols: [''],
  annual_cash_flow_discount: 0.15,
  margin_of_safety: 0.3,
  number_of_years: 10,
  maximal_average_growth_rate: 0.1
}

export const defaultGrowthPeriods: number[] = [2, 5, 10]
