import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { FinancialsGrowthResponseData } from './types' // Assuming you have these types in a file
import { getIndicatorColorForValue, tableColors } from './colors'

type Props = {
  data: FinancialsGrowthResponseData;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '10px',
  border: '1px solid #ccc',
  textAlign: 'left',
}));

const CenterAlignTableCell = styled(StyledTableCell)({
  padding: '5px',
  textAlign: 'center',
});

const StickyCompanyCell = styled(StyledTableCell)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  backgroundColor: tableColors.describingCellBackground,
  zIndex: theme.zIndex.appBar + 2,
  width: '100px',
  fontWeight: 'bold',
  verticalAlign: 'middle',
  border: '1px solid #ccc',
  boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
}));

const StickyMetricCell = styled(StyledTableCell)(({ theme }) => ({
  position: 'sticky',
  left: '100px',
  backgroundColor: tableColors.describingCellBackground,
  zIndex: theme.zIndex.appBar + 1,
  width: '150px',
  border: '1px solid #ccc',
  boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
}));

const StickyHeaderCompanyCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: theme.zIndex.appBar + 3,
  backgroundColor: tableColors.headerCellBackground,
  width: '100px',
  fontWeight: 'bold',
  border: '1px solid #ccc',
  boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
}));

const StickyHeaderMetricCell = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: '100px',
  zIndex: theme.zIndex.appBar + 2,
  backgroundColor: tableColors.headerCellBackground,
  width: '150px',
  fontWeight: 'bold',
  border: '1px solid #ccc',
  boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
}));

const CenterAlignedHeaderCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar + 1,
  backgroundColor: tableColors.headerCellBackground,
  border: '1px solid #ccc',
  fontWeight: 'bold',
}));

const CompanyRowSeparator = styled(TableRow)({
  '& td': {
    height: 0,
    padding: 0,
    margin: 0,
    border: 'none',
    borderBottom: '1px solid #ccc',
    lineHeight: 0,
  },
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // Additional styles if needed
}));

const FinancialsGrowthTable: React.FC<Props> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  console.log("Mobile view active: " + isMobile)

  // Extract the periods for the header
  const periods =
    data.financials_growths[0]?.financials_growth.map((record) => record.period) || [];

  return (
    <TableContainer component={Paper} style={{ marginTop: '20px', overflowX: 'auto', maxHeight: '90vh' }}>
      <Table
        stickyHeader
        style={{ tableLayout: 'auto', borderCollapse: 'collapse' }}
        aria-label="Financials Growth Table"
      >
        <TableHead>
          <TableRow>
            <StickyHeaderCompanyCell>Company</StickyHeaderCompanyCell>
            <StickyHeaderMetricCell>Metric</StickyHeaderMetricCell>
            {periods.map((period, index) => (
              <CenterAlignedHeaderCell key={index}>{period}</CenterAlignedHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.financials_growths.map((company, companyIndex) => (
            <React.Fragment key={company.symbol}>
              {/* Row separator between companies */}
              {companyIndex !== 0 && (
                <CompanyRowSeparator>
                  <TableCell colSpan={periods.length + 2} />
                </CompanyRowSeparator>
              )}
              {/* Equity Row */}
              <StyledTableRow>
                <StickyCompanyCell rowSpan={4}>{company.symbol}</StickyCompanyCell>
                <StickyMetricCell>Equity</StickyMetricCell>
                {company.financials_growth.map((record, index) => {
                  const style = getIndicatorColorForValue(record.equity);
                  return (
                    <CenterAlignTableCell key={index} style={style}>
                      {(record.equity * 100).toFixed(2)}%
                    </CenterAlignTableCell>
                  );
                })}
              </StyledTableRow>
              {/* Free Cash Flow Row */}
              <StyledTableRow>
                <StickyMetricCell>Free Cash Flow</StickyMetricCell>
                {company.financials_growth.map((record, index) => {
                  const style = getIndicatorColorForValue(record.free_cash_flow);
                  return (
                    <CenterAlignTableCell key={index} style={style}>
                      {(record.free_cash_flow * 100).toFixed(2)}%
                    </CenterAlignTableCell>
                  );
                })}
              </StyledTableRow>
              {/* Revenue Row */}
              <StyledTableRow>
                <StickyMetricCell>Revenue</StickyMetricCell>
                {company.financials_growth.map((record, index) => {
                  const style = getIndicatorColorForValue(record.revenue);
                  return (
                    <CenterAlignTableCell key={index} style={style}>
                      {(record.revenue * 100).toFixed(2)}%
                    </CenterAlignTableCell>
                  );
                })}
              </StyledTableRow>
              {/* Earnings Per Share (Diluted) Row */}
              <StyledTableRow>
                <StickyMetricCell>Earnings Per Share (Diluted)</StickyMetricCell>
                {company.financials_growth.map((record, index) => {
                  const style = getIndicatorColorForValue(record.earnings_per_share_diluted);
                  return (
                    <CenterAlignTableCell key={index} style={style}>
                      {(record.earnings_per_share_diluted * 100).toFixed(2)}%
                    </CenterAlignTableCell>
                  );
                })}
              </StyledTableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FinancialsGrowthTable;
