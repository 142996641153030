import { CompanyInformation, DiscountedCashFlowResult } from './types'

export const companyInfoKeys: (keyof CompanyInformation)[] = ["symbol", "market_cap"];
export const dcfKeys: (keyof DiscountedCashFlowResult)[] = [
  "intrinsic_value",
  "average_annual_growth_rate",
  "initial_free_cash_flow",
  "average_market_cap_to_free_cash_flow_ratio",
  "liquid_assets_value",
  "highest_acceptable_buy_price"
];
