import { RGBColor } from './types'
import React from 'react'

export const tableColors = {
  headerCellBackground: '#f4f4f4',
  describingCellBackground: '#f9f9f9'
}

export const indicatorColors: {[key: string]: {[key: string]: RGBColor} } = {
  fontColors: {
    green: [21, 87, 36],     // #155724
    orange: [133, 100, 4],   // #856404
    red: [114, 28, 36]       // #721c24
//   green: [0, 97, 0],       // Dark green
//   orange: [156, 87, 0],    // Dark orange
//   red: [156, 0, 6],        // Dark red
  },
  backgroundColors: {
    green: [212, 237, 218],  // #d4edda
    orange: [255, 243, 205], // #fff3cd
    red: [248, 215, 218]     // #f8d7da
//   green: [198, 239, 206],  // Light green
//   orange: [255, 235, 156], // Light orange
//   red: [255, 199, 206],    // Light red
  }
}

const interpolateColor = (
  color1: RGBColor,
  color2: RGBColor,
  ratio: number
): RGBColor => {
  const r = Math.round(color1[0] + (color2[0] - color1[0]) * ratio);
  const g = Math.round(color1[1] + (color2[1] - color1[1]) * ratio);
  const b = Math.round(color1[2] + (color2[2] - color1[2]) * ratio);
  return [r, g, b];
};

// Function to get interpolated color based on value
export const getIndicatorColorForValue = (value: number): React.CSSProperties => {
  let backgroundColor: RGBColor
  let fontColor: RGBColor

  if (value >= 0.1) {
    // Fixed green
    backgroundColor = indicatorColors.backgroundColors.green
    fontColor = indicatorColors.fontColors.green
  } else if (value >= 0.07 && value < 0.1) {
    // Interpolate between green and orange
    const ratio = (value - 0.07) / (0.1 - 0.07) // Normalize ratio
    backgroundColor = interpolateColor(indicatorColors.backgroundColors.orange,
      indicatorColors.backgroundColors.green, ratio)
    fontColor = interpolateColor(indicatorColors.fontColors.orange,
      indicatorColors.fontColors.green, ratio)
  } else if (value >= 0.01 && value < 0.07) {
    // Interpolate between orange and red
    const ratio = (value - 0.01) / (0.07 - 0.01) // Normalize ratio
    backgroundColor = interpolateColor(indicatorColors.backgroundColors.red,
      indicatorColors.backgroundColors.orange, ratio)
    fontColor = interpolateColor(indicatorColors.fontColors.red,
      indicatorColors.fontColors.orange, ratio)
  } else {
    // Fixed red for values < 0.01
    backgroundColor = indicatorColors.backgroundColors.red
    fontColor = indicatorColors.fontColors.red
  }

  return {
    backgroundColor: `rgb(${backgroundColor[0]}, ${backgroundColor[1]}, ${backgroundColor[2]})`,
    color: `rgb(${fontColor[0]}, ${fontColor[1]}, ${fontColor[2]})`,
  }
}